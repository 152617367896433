<template>
    <nav class="navbar navbar-expand-lg">
      <a class="navbar-brand" href="#">
        <img src="@/assets/minecraft_logo.png" width="30" height="30" alt="Minecraft Logo">
        凌云之都
      </a>
      <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link active" href="#">
              <i class="bi bi-house"></i>首页
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">公告</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://qm.qq.com/q/8vZcBo18vC">加入我们</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://fk.lyzd.fun">赞助服务器</a>
          </li>
        </ul>
      </div>
    </nav>
  </template>
  
  <script>
  </script>
  
  <style scoped>
 
  </style>
  